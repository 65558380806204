// Generated by CoffeeScript 1.10.0
(function() {
  var _countScrollbar, countScrollbar;

  _countScrollbar = null;

  countScrollbar = function() {
    var t;
    if (_countScrollbar !== null) {
      return _countScrollbar;
    }
    t = document.createElement('textarea');
    t.style.width = '50px';
    t.style.height = '50px';
    t.style.border = 'none';
    t.style.padding = '0';
    document.body.appendChild(t);
    _countScrollbar = t.clientWidth !== window.getComputedStyle(t).width;
    document.body.removeChild(t);
    return _countScrollbar;
  };

  angular.module('input-highlight', []).directive('highlight', [
    '$parse', function($parse) {
      return {
        restrict: 'A',
        link: function(scope, el, attrs) {
          var canvas, container, ctx, formatting, i, input, len, mirror, onChange, prop, render, sizeProps, spread, style;
          input = el[0];
          if (input.tagName !== 'TEXTAREA') {
            return;
          }
          spread = 2;
          mirror = angular.element('<div style="position:relative"></div>')[0];
          container = angular.element('<div style="position:absolute;width:0px;height:0px;overflow:hidden;"></div>')[0];
          sizeProps = ['width', 'font-size', 'font-family', 'font-style', 'font-weight', 'font-variant', 'font-stretch', 'line-height', 'vertical-align', 'word-spacing', 'text-align', 'letter-spacing', 'text-rendering', 'margin-top', 'margin-right', 'margin-bottom', 'margin-left', 'padding-top', 'padding-right', 'padding-bottom', 'padding-left', 'box-sizing'];
          if (countScrollbar()) {
            sizeProps.push('overflow-y');
          }
          document.body.appendChild(container);
          container.appendChild(mirror);
          el.css({
            'background-position': '0 0',
            'background-repeat': 'no-repeat'
          });
          style = window.getComputedStyle(input);
          mirror.style['white-space'] = 'pre-wrap';
          mirror.style['width'];
          for (i = 0, len = sizeProps.length; i < len; i++) {
            prop = sizeProps[i];
            mirror.style[prop] = style[prop];
          }
          if (style['resize'] === 'both') {
            el.css('resize', 'vertical');
          }
          if (style['resize'] === 'horizontal') {
            el.css('resize', 'none');
          }
          formatting = scope[attrs.highlight] || {};
          onChange = angular.noop;
          if (attrs.highlightOnchange) {
            onChange = (function() {
              var fn;
              fn = $parse(attrs.highlightOnchange);
              return function(markers) {
                return fn(scope, {
                  $markers: markers
                });
              };
            })();
          }
          canvas = document.createElement('canvas');
          ctx = canvas.getContext('2d');
          render = function(text) {
            var color, containerRect, coords, data, j, k, len1, len2, marker, markers, offsetX, offsetY, originalText, re, rect, rects, ref, ref1;
            markers = [];
            originalText = text;
            mirror.innerHTML = text;
            mirror.style.width = style.width;
            canvas.width = mirror.clientWidth;
            canvas.height = mirror.clientHeight;
            for (color in formatting) {
              re = formatting[color];
              mirror.innerHTML = text.replace(re, function(s) {
                return "<span style=\"position:relative;background:red;\" data-marker=\"" + color + "\">" + s + "</span>";
              });
              containerRect = mirror.getClientRects()[0];
              offsetX = containerRect.left;
              offsetY = containerRect.top;
              ref = mirror.querySelectorAll('span[data-marker]');
              for (j = 0, len1 = ref.length; j < len1; j++) {
                marker = ref[j];
                data = {
                  text: marker.innerHTML,
                  color: marker.getAttribute('data-marker')
                };
                rects = [];
                ref1 = marker.getClientRects();
                for (k = 0, len2 = ref1.length; k < len2; k++) {
                  rect = ref1[k];
                  coords = {
                    x: rect.left - offsetX - spread,
                    y: rect.top - offsetY,
                    width: rect.width + 2 * spread - 1,
                    height: rect.height + 1
                  };
                  ctx.fillStyle = color;
                  ctx.fillRect(coords.x, coords.y, coords.width, coords.height);
                  rects.push(coords);
                }
                data.rectsgit = rects;
                markers.push(data);
              }
            }
            el.css('background-image', "url(" + (canvas.toDataURL()) + ")");
            return onChange(markers);
          };
          if (attrs.ngModel) {
            scope.$watch(attrs.ngModel, render);
          } else {
            render(input.value);
            anguar.element(input).on('change', function() {
              return render(this.value);
            });
          }
          scope.$watch(attrs.highlight, function(_formatting) {
            formatting = _formatting || {};
            return render(input.value);
          }, true);
          scope.$on('$destroy', function() {
            return container.parentNode.removeChild(container);
          });
          return el.on('scroll', function() {
            return el.css('background-position', "0 -" + input.scrollTop + "px");
          });
        }
      };
    }
  ]);

}).call(this);
