import "expose-loader?$!expose-loader?jQuery!jquery";
import "bootstrap/less/bootstrap.less";
import "./skin/bootstrap/js/bootstrap.min.js";
import "./skin/less/AdminLTE.less";
import "./skin/less/corporateChat.less";
import "./skin/less/blockedList.less";
import "font-source-sans-pro/css/main.css";
import "angular-ui-select/css/select";
import "angular-tree-control/css/tree-control";
import "angular-tree-control/css/tree-control-attribute";
import "expose-loader?angular!angular";
import "angular-scroll-glue/js/scrollglue.js";
import "angular-hotkeys/js/hotkeys.js";
import "angular-hotkeys/css/hotkeys.css";
import "angular-cookies/js/angular-cookies";
import "angular-input-highlight/js/angular-input-highlight"
import "expose-loader?d3!d3"
import "nvd3-css"
import "angular-nvd3/js/angular-nvd3"
import "angular-route/js/angular-route";
import "angular-touch/js/angular-touch";
import "angular-resource/js/angular-resource";
import "@uirouter/angularjs";
import "angular-ui-utils/js/ui-utils";
import "angular-sanitize/js/angular-sanitize-fixed";
import "angular-tree-control/js/angular-tree-control";
import "angular-animate/js/angular-animate";
import "angular-ui-select/js/select-modified";
import "angular-file-upload/dist/angular-file-upload";
import "formdata-polyfill/FormData";
import "lodash/js/lodash";
import "jquery-ui/js/jquery-ui";
import "angular-dragdrop/js/angular-dragdrop"
import "restangular/js/restangular-fixed";
import "components-font-awesome/less/font-awesome.less";
import "datatables/css/jquery.dataTables";
import "datatables/js/jquery.dataTables";
import "console.style/console.style";
import "angular-datatables/css/angular-datatables.css";
import "angular-datatables/css/datatables.bootstrap.min.css";
import "angular-datatables/js/angular-datatables.js";
import "angular-datatables/js/angular-datatables.bootstrap.js";
import "angular-datatables/js/angular-datatables.columnfilter.js";
import "angular-datatables/js/angular-datatables.select.js";
import "ng-tags-input/ng-tags-input.bootstrap.css";
import "ng-tags-input/ng-tags-input.min.css";
import "ng-tags-input/ng-tags-input.min.js";
import "angular-bootstrap/js/ui-bootstrap-tpls.js";
import "bootstrap-daterangepicker/css/daterangepicker.css";
import "expose-loader?moment!moment/js/moment";
import "expose-loader?dagre!dagre/dist/dagre";
import "imports-loader?this=>window,define=>false,module=>false,require=>false!bootstrap-daterangepicker/js/daterangepicker.js";
import "angular-daterangepicker/js/angular-daterangepicker";
import "checklist-model/js/checklist-model.js";
import "angular-ui-tree/js/angular-ui-tree";
import "angular-ui-tree/css/angular-ui-tree";
import "material-design-icons/material-icons.css";
import "angular-tree-dnd/dist/ng-tree-dnd.js";
import "angular-tree-dnd/dist/ng-tree-dnd.css";
import "angular-bootstrap-colorpicker/css/colorpicker.css";
import "angular-bootstrap-colorpicker/js/bootstrap-colorpicker-module.js";
import "angular-loopify-number-picker/dist/loopify-ui-number-picker.js";
import "angular-loopify-number-picker/dist/loopify-ui-number-picker.min.css";
import "angular-loopify-number-picker/dist/loopify-ui-number-picker-templates.js";
import "angular-file-saver/js/angular-file-saver.bundle.js";
import "angular-random-string/src/angular-random-string.js";
import "humanize-duration/js/humanize-duration.js";
import "magnific-popup/css/magnific-popup.css";
import "magnific-popup/js/jquery.magnific-popup.js";
import "angular-messages/js/angular-messages.js";
import "expose-loader?Mustache!mustache.js/js/mustache.js";
import "./font/Icon/Icon.font.js";
import "ngclipboard/js/ngclipboard.js"
import "expose-loader?ClipboardJS!clipboard/js/clipboard.js"
import "expose-loader?showJSError!show-js-error/dist/show-js-error.custom.js"
import "show-js-error/dist/show-js-error.css"
import "angular-gridster/css/angular-gridster-fixed.css"
import "angular-gridster/js/angular-gridster-fixed.js"
import "angular-ui-grid/js/ui-grid.js"
import "angular-ui-grid/css/ui-grid.css"
// экспортируем moment в window
// попробуем добавить pagination
